import clsx from 'clsx'
import { Provider, Root, Title, Viewport } from '@radix-ui/react-toast'

import { ReactComponent as CloseIcon } from '/public/assets/icons/close.svg'
import { ReactComponent as CheckIcon } from '/public/assets/icons/Check.svg'

import styles from './_.module.css'

interface Props {
  type?: 'error' | 'success' | 'info'
  swipeDirection?: 'up' | 'down' | 'right'
  title: string
}

const ToastDemo = ({
  type = 'info',
  swipeDirection = 'right',
  title
}: Props) => {
  function getIcon(type: string) {
    switch (type) {
      case 'error':
        return (
          <div className="bg-orange-70  | rounded-full | flex items-center justify-center | w-5 h-5 | p-0.5 m-1">
            <CloseIcon className="text-white" />
          </div>
        )
      case 'success':
        return (
          <div className="bg-green-70 | rounded-full | flex items-center justify-center | w-5 h-5 | p-1 m-1">
            <CheckIcon className="text-white" />
          </div>
        )
      default:
        break
    }
  }

  return (
    <Provider swipeDirection={swipeDirection}>
      <Root className={clsx(styles.toastRoot)}>
        <div className="bg-white text-black | rounded-md shadow-2 | max-w-lg | p-4 m-4">
          <Title className="flex gap-2">
            {getIcon(type)}
            {title}
          </Title>
        </div>
      </Root>
      {title.length > 1 ? (
        <Viewport className="flex gap-3 | fixed bottom-0 right-0 z-[9999] | max-w-full | p-6  m-0 | list-none outline-none" />
      ) : null}
    </Provider>
  )
}

export default ToastDemo
