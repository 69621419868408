import clsx from 'clsx'
import Image from 'next/image'
import { useEffect } from 'react'
import styles from './_.module.css'

interface Props {
  title?: string
  class?: string
  mode?: 'light' | 'dark' | 'purple'
  brands: StrapiImageFile[]
  awards?: StrapiImageFile[]
}

function CustomerLogo({
  title,
  mode = 'dark',
  brands = [],
  awards,
  ...props
}: Props): JSX.Element {
  useEffect(() => {
    if (brands.length > 0) {
      logoSliderObserver() //observer to remove animation since the images are outside the viewport
    }
  }, [brands])

  return (
    <section
      className={clsx(
        props.class,
        mode === 'dark'
          ? 'bg-blue-web-90'
          : mode === 'purple'
          ? 'bg-gradient-to-b from-[#070425] to-[#340F73]'
          : null
      )}
    >
      <div
        className={clsx('container max-w-screen-2xl mx-auto | py-10 md:py-18')}
      >
        {title ? (
          <h2
            className={clsx(
              mode === 'dark'
                ? 'text-white'
                : mode === 'purple'
                ? 'text-white'
                : '',
              'font-heading text-2xl text-center font-semibold uppercase',
              awards && awards.length > 0 ? 'mb-6 md:mb-10' : 'mb-14 md:mb-8'
            )}
          >
            {title}
          </h2>
        ) : null}
        {awards ? (
          <div
            className={clsx(
              'grid grid-cols-3 md:grid-cols-6 gap-4 sm:gap-7 | md:max-w-col-9 md:mx-auto mb-10 md:mb-14'
            )}
          >
            {awards.map((award, index) => (
              <Image
                key={index}
                src={award.url}
                alt={award.alternativeText ?? ''}
                height={award.height}
                width={award.width}
                loading="lazy"
                className="mx-auto"
              />
            ))}
          </div>
        ) : null}
        <div
          className={clsx(
            styles.gradientAtMargin,
            mode === 'dark'
              ? styles.gradientColorAtDark
              : styles.gradientColorAtDefault,
            'overflow-hidden sm:relative sm:flex'
          )}
          id="logo-slider-wrapper"
        >
          <LogoImages brands={brands} mode={mode} />
        </div>
      </div>
    </section>
  )
}

function LogoImages({
  brands = [],
  mode
}: {
  brands?: StrapiImageFile[]
  mode?: string
}) {
  return (
    <>
      {[...Array(3)].map((_, index) => (
        <div
          key={index}
          className={clsx(
            styles.logoSlider,
            'slider | flex flex-wrap sm:flex-nowrap | gap-x-8 gap-y-6 sm:gap-0 | justify-center sm:justify-around sm:justify-items-center | sm:relative'
          )}
          style={{ animationDuration: `calc(${2 * brands.length}s)` }}
        >
          {brands.map((brand, index) => (
            <Image
              key={`logo_${index}`}
              src={brand.url}
              className={clsx(
                'logo | min-w-[8rem] max-h-14 | hidden sm:block | sm:mr-10',
                mode === 'light' ? 'invert' : 'invert-0'
              )}
              alt={brand.alternativeText ?? ''}
              height={brand.height}
              width={brand.width}
              loading="lazy"
            />
          ))}
        </div>
      ))}
    </>
  )
}

// remove loading attribute since the images are outside the viewport and lazy loading images
const logoSliderObserver = () => {
  let isLoadingAttributeRemoved = false
  const observer = new IntersectionObserver(handleSliderIntersection, {
    threshold: 0.25
  })
  const slider = document.querySelector('#logo-slider-wrapper') as HTMLElement
  if (slider) {
    slider.parentElement && observer.observe(slider.parentElement)
  }
  // remove loading attribute since the images are outside the viewport and safari doesn't load those images
  function handleSliderIntersection(entries: IntersectionObserverEntry[]) {
    if (!slider) return
    if (entries[0].isIntersecting) {
      slider.style.animationPlayState = 'running'
      removeLoadingLazyAttribute()
    } else slider.style.animationPlayState = 'paused'
  }

  function removeLoadingLazyAttribute() {
    if (isLoadingAttributeRemoved) return
    const sliderImages = slider?.querySelectorAll(
      '.logo'
    ) as NodeListOf<HTMLImageElement>
    sliderImages.forEach(image => {
      image.removeAttribute('loading')
    })
    isLoadingAttributeRemoved = true
  }
}

export default CustomerLogo
