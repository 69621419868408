import Image from 'next/image'
import clsx from 'clsx'

import Link from '@/atoms/Link/Link'

function UseCase() {
  return (
    <section className="bg-gradient-to-b from-[#0E0182] to-[#0B0169] text-white">
      <div className="container | py-10 md:py-18">
        <h2 className="font-heading font-semibold text-3xl md:text-5xl | mb-10 md:mb-14 max-w-col-7">
          Create content that drives ROI
        </h2>
        <div className="gallery | grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="lg:row-[1/3]">
            <Link href="/seo/" target="">
              <div
                className={clsx(
                  'grid grid-rows-[auto_1fr] | h-full bg-gradient-to-b from-[#cc970033] to-[#FFD55E] | rounded-xl relative group overflow-hidden'
                )}
              >
                <div className="flex justify-between | p-6 md:px-8 md:py-11">
                  <div>
                    <div className="flex items-center gap-2 | mb-3 md:mb-5">
                      <div>
                        <Image
                          src="/assets/homePage/images/use-case/calendar-search-seo.svg"
                          alt=""
                          width={40}
                          height={40}
                          className="w-4 h-4 md:w-5 md:h-5 mx-auto"
                        />
                      </div>
                      <div className="text-xs md:text-base uppercase text-yellow-40">
                        seo
                      </div>
                    </div>
                    <div>
                      <h2 className="font-heading text-2xl md:text-3xl font-medium | max-w-[155px] md:max-w-col-3 mb-3 md:mb-10">
                        Win the new age of SEO
                      </h2>
                      <div className="text-small md:text-sm | md:max-w-col-4">
                        Drive high-intent traffic with predictive insights, the
                        world’s most powerful SEO content co-pilot, and expert
                        talent to produce original content.
                      </div>
                    </div>
                  </div>
                  <Image
                    src="/assets/icons/overview/circular-right.svg"
                    alt=""
                    width={55}
                    height={55}
                    className="w-10 h-10 md:w-14 md:h-14 opacity-50 group-hover:opacity-100 group-hover:-rotate-45 transition-all ease-out"
                  />
                </div>
                <Image
                  src="/assets/homePage/images/use-case/use-case-seo-image.png"
                  alt=""
                  width={525}
                  height={435}
                  className="w-full mt-auto"
                />
                <div className="absolute inset-0 bg-transparent border border-transparent group-hover:border-[#FFD55E] transition-all rounded-xl"></div>
              </div>
            </Link>
          </div>
          <div className="lg:row-[1/2]">
            <Link href="/demand-gen/" target="">
              <div
                className={clsx(
                  'bg-gradient-to-b from-[#4de5934d] to-[#19BF65] | rounded-xl relative group overflow-hidden'
                )}
              >
                <div className="hidden md:block">
                  <div className="flex justify-between">
                    <div className="p-6 md:px-8 md:py-11">
                      <div className="flex items-center gap-2 | md:mb-5">
                        <div>
                          <Image
                            src="/assets/homePage/images/use-case/chart-demand-gen.svg"
                            alt=""
                            width={40}
                            height={40}
                            className="w-10 h-10 md:w-5 md:h-5 mx-auto"
                          />
                        </div>
                        <div className="text-xs md:text-base uppercase text-green-40">
                          Demand Gen
                        </div>
                      </div>
                      <h2 className="font-heading text-2xl md:text-3xl font-medium | max-w-col-3 md:mb-10">
                        Dominate the dark funnel
                      </h2>
                      <div className="text-small md:text-sm | md:max-w-col-4 md:mb-8">
                        Create dynamic thought leadership and demand gen content
                        with top 3% industry experts-supercharged by AI tools.
                      </div>
                      <div>
                        <Image
                          src="/assets/icons/overview/circular-right.svg"
                          alt=""
                          width={55}
                          height={55}
                          className="w-14 h-14 md:w-14 md:h-14 opacity-50 group-hover:opacity-100 group-hover:-rotate-45 transition-all ease-out"
                        />
                      </div>
                    </div>
                    <Image
                      src="/assets/homePage/images/use-case/use-case-dem-gen-desk-image.png"
                      alt=""
                      width={219}
                      height={368}
                    />
                  </div>
                </div>
                <div className="block md:hidden">
                  <div className="flex justify-between | p-6 md:px-8 md:py-11">
                    <div>
                      <div className="flex items-center gap-2 | mb-3 md:mb-5">
                        <div>
                          <Image
                            src="/assets/homePage/images/use-case/chart-demand-gen.svg"
                            alt=""
                            width={40}
                            height={40}
                            className="w-4 h-4 md:w-5 md:h-5 mx-auto"
                          />
                        </div>
                        <div className="text-xs md:text-base uppercase text-green-40">
                          demand gen
                        </div>
                      </div>
                      <div>
                        <h2 className="font-heading text-2xl md:text-3xl font-medium | max-w-[155px] md:max-w-col-3 mb-3 md:mb-10">
                          Dominate the dark funnel
                        </h2>
                        <div className="text-small md:text-sm | md:max-w-col-4">
                          Create dynamic thought leadership and demand gen
                          content with top 3% industry experts-supercharged by
                          AI tools.
                        </div>
                      </div>
                    </div>
                    <Image
                      src="/assets/icons/overview/circular-right.svg"
                      alt=""
                      width={55}
                      height={55}
                      className="w-10 h-10 md:w-14 md:h-14 opacity-50 group-hover:opacity-100 group-hover:-rotate-45 transition-all ease-out"
                    />
                  </div>
                  <Image
                    src="/assets/homePage/images/use-case/use-case-dem-gen-mob-image.png"
                    alt=""
                    width={477}
                    height={192}
                    className="w-full"
                  />
                </div>
                <div className="absolute inset-0 bg-transparent border border-transparent group-hover:border-[#19BF65] transition-all rounded-xl"></div>
              </div>
            </Link>
          </div>
          <div className="lg:row-[2/3]">
            <Link href="/video-services/" target="">
              <div
                className={clsx(
                  'bg-gradient-to-b from-[#281F85] to-[#6F1DE2] | rounded-xl relative group overflow-hidden'
                )}
              >
                <div className="hidden md:block">
                  <div className="flex justify-between">
                    <div className="p-6 md:px-8 md:py-11">
                      <div className="flex items-center gap-2 | md:mb-5">
                        <div>
                          <Image
                            src="/assets/homePage/images/use-case/video-play.svg"
                            alt=""
                            width={40}
                            height={40}
                            className="w-10 h-10 md:w-5 md:h-5 mx-auto"
                          />
                        </div>
                        <div className="text-xs md:text-base uppercase text-magenta-20">
                          video
                        </div>
                      </div>
                      <h2 className="font-heading text-2xl md:text-3xl font-medium | max-w-col-3 md:mb-10">
                        Stop the scroll
                      </h2>
                      <div className="text-small md:text-sm | md:max-w-col-4 md:mb-8">
                        Create videos that captivate your audience and drive
                        leads—social videos, product explainers, commercials,
                        and more.
                      </div>
                      <div>
                        <Image
                          src="/assets/icons/overview/circular-right.svg"
                          alt=""
                          width={55}
                          height={55}
                          className="w-14 h-14 md:w-14 md:h-14 opacity-50 group-hover:opacity-100 group-hover:-rotate-45 transition-all ease-out"
                        />
                      </div>
                    </div>
                    <Image
                      src="/assets/homePage/images/use-case/use-case-video-desk-image.png"
                      alt=""
                      width={219}
                      height={368}
                    />
                  </div>
                </div>
                <div className="block md:hidden">
                  <div className="flex justify-between | p-6 md:px-8 md:py-11">
                    <div>
                      <div className="flex items-center gap-2 | mb-3 md:mb-5">
                        <div>
                          <Image
                            src="/assets/homePage/images/use-case/video-play.svg"
                            alt=""
                            width={40}
                            height={40}
                            className="w-4 h-4 md:w-5 md:h-5 mx-auto"
                          />
                        </div>
                        <div className="text-xs md:text-base uppercase text-magenta-20">
                          video
                        </div>
                      </div>
                      <div>
                        <h2 className="font-heading text-2xl md:text-3xl font-medium | max-w-[155px] md:max-w-col-3 mb-3 md:mb-10 group-hover:font-semibold">
                          Stop the scroll
                        </h2>
                        <div className="text-small md:text-sm | md:max-w-col-4">
                          Create videos that captivate your audience and drive
                          leads—social videos, product explainers, commercials,
                          and more.
                        </div>
                      </div>
                    </div>
                    <Image
                      src="/assets/icons/overview/circular-right.svg"
                      alt=""
                      width={55}
                      height={55}
                      className="w-10 h-10 md:w-14 md:h-14 opacity-50 group-hover:opacity-100 group-hover:-rotate-45 transition-all ease-out"
                    />
                  </div>
                  <Image
                    src="/assets/homePage/images/use-case/use-case-video-mob-image.png"
                    alt=""
                    width={477}
                    height={192}
                    className="w-full"
                  />
                </div>
                <div className="absolute inset-0 bg-transparent border border-transparent group-hover:border-[#6F1DE2] transition-all rounded-xl"></div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default UseCase
