import { ReactText, useEffect, useRef, useState } from 'react'
import Image from 'next/image'
import clsx from 'clsx'

/* blocks */
import Link from '@/atoms/Link/Link'
import Accordion from '@/atoms/Accordion/Accordion'

/* assets */
import { ReactComponent as ArrowRight } from 'public/assets/icons/chevron-right.svg'

/* styling */
import styles from './_.module.css'

interface Props {
  list: {
    id: string | number
    title: string
    description: string
    bgColor?: string
    media?: StrapiImageFile
    ctaLink?: string
  }[]
}

function ContentStrategy({ list }: Props): JSX.Element {
  const firstFeature = list[0]
  const [activeKey, setActiveKey] = useState<ReactText | undefined>(list[0]?.id)
  const [activeMedia, setActiveMedia] = useState({ ...firstFeature })
  const contentSectionRef = useRef<HTMLDivElement | null>(null)
  const animatedLineRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // Set the height of the animatedLineRef to 100% when intersecting
            if (animatedLineRef.current) {
              animatedLineRef.current?.classList.add(styles.sideLineFadeIn)
              animatedLineRef.current?.classList.remove(styles.sideLineFadeOut)
            }
          } else {
            // Set the height of the animatedLineRef to 0 when not intersecting
            if (animatedLineRef.current) {
              animatedLineRef.current?.classList.add(styles.sideLineFadeOut)
              animatedLineRef.current?.classList.remove(styles.sideLineFadeIn)
            }
          }
        })
      },
      {
        rootMargin: '200px 0px -200px 0px'
      }
    )

    if (animatedLineRef.current) {
      observer.observe(animatedLineRef.current)
    }

    return () => {
      if (animatedLineRef.current) {
        observer.unobserve(animatedLineRef.current)
      }
    }
  }, [animatedLineRef])

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            contentSectionRef.current?.classList.remove(styles.fadeOut) // Remove fadeOut class if present
            contentSectionRef.current?.classList.add(styles.fadeIn)
          } else {
            contentSectionRef.current?.classList.remove(styles.fadeIn) // Remove fadeIn class if not intersecting
            contentSectionRef.current?.classList.add(styles.fadeOut) // Add the fadeOut class
          }
        })
      },
      {
        rootMargin: '200px 0px -200px 0px'
      }
    )

    if (contentSectionRef.current) {
      observer.observe(contentSectionRef.current)
    }

    return () => {
      if (contentSectionRef.current) {
        observer.unobserve(contentSectionRef.current)
      }
    }
  }, [contentSectionRef])

  return (
    <section className="bg-blue-web-90">
      <div className="container">
        <div className="flex items-center gap-3">
          <div className="flex flex-col items-center basis-1/12 flex-shrink-0">
            <Image
              src="/assets/homePage/images/services/content-strategy-icon.svg"
              alt=""
              width={45}
              height={45}
              className="w-11 h-11"
            />
          </div>
          <div className="text-cyan-60 font-semibold | basis-full">
            Content Strategy
          </div>
        </div>
        <div className="flex gap-3">
          <div className="flex flex-col items-center basis-1/12 flex-shrink-0">
            <div
              ref={animatedLineRef}
              className="animatedLine w-[2px] h-0 rounded-md | mx-auto"
              style={{
                background:
                  'linear-gradient(180deg, #070425 0%, #00C5E5 19.27%, #00C5E5 54.17%, #070425 100%)',
                transition: 'height 0.5s ease'
              }}
              data-build-delay="500"
            />
          </div>

          <div className="basis-full overflow-x-hidden | mb-18">
            <div className="title font-heading text-3xl md:text-5xl text-white font-semibold | max-w-col-8 pt-7 mb-14">
              Work with our experts to create a robust content strategy
            </div>
            <div
              ref={contentSectionRef}
              className="border border-[#251F60] rounded-2xl bg-gradient-to-b from-[#251F60] to-[#251f6000] overflow-hidden opacity-0"
            >
              <div className="grid grid-cols-1 md:grid-cols-12 gap-8 items-center | mt-8 ml-4 lg:mt-9 lg:ml-10">
                <div className="text-white flex flex-col gap-5 md:gap-8 | md:col-span-5 mr-4 md:mr-0">
                  {list?.map(feature => {
                    return (
                      <Accordion
                        key={feature.id}
                        className={clsx(styles.accordion)}
                        activeKey={activeKey}
                        defaultOpen={feature.id === list[0].id}
                        elementKey={feature.id}
                        showIcon={true}
                        IconClass={clsx(styles.icon)}
                        IconPosition="right"
                        title={feature.title}
                        handleChange={key => {
                          setActiveKey(key)
                          setActiveMedia(feature ?? activeMedia)
                        }}
                      >
                        <div
                          className="text-caption md:text-small lg:text-base text-white opacity-60 | mb-4 md:mb-8"
                          dangerouslySetInnerHTML={{
                            __html: feature.description
                          }}
                        ></div>
                        {feature.ctaLink ? (
                          <Link
                            href={feature.ctaLink}
                            target="_blank"
                            className="flex items-center text-caption md:text-sm font-semibold | mb-4 md:mb-8"
                          >
                            Get Started
                            <ArrowRight className="ml-2" />
                          </Link>
                        ) : null}
                      </Accordion>
                    )
                  })}
                </div>

                <div className="rounded-tl-xl md:rounded-tl-2xl relative | m-auto md:col-start-7 md:col-end-13">
                  {activeMedia ? (
                    <Image
                      className={clsx(styles.scheduleList)}
                      key={activeMedia.media?.url}
                      src={activeMedia.media?.url ?? ''}
                      width={activeMedia.media?.width}
                      height={activeMedia.media?.height}
                      alt={activeMedia.media?.alternativeText ?? ''}
                      priority={true}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContentStrategy
